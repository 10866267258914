import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        {/* <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Are you ready to join our journey?
              </h3>
          </div>
          <div className="cta-action">
              <Button tag="a" color="primary" wideMobile href="https://docs.google.com/forms/d/e/1FAIpQLSeUr1sA-idHsyRfr9W1jDqFokRF_j7mYWLL69UPNgzddFMmmQ/viewform">
                          Join Us
              </Button>
              <span />
              <Button tag="a" color="dark" wideMobile href="https://docs.google.com/forms/d/e/1FAIpQLSeUr1sA-idHsyRfr9W1jDqFokRF_j7mYWLL69UPNgzddFMmmQ/viewform">
                            Sign Up
              </Button>
          </div>
        </div> */}
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;